import { useState, useEffect } from "react";

export const useAddThis = () => {
    const isBrowser = typeof window !== "undefined";
    const shouldLoad = (isBrowser && window.location.pathname.includes("resources")) ?? false;
    const [loadAddThis, setLoadAddThis] = useState(false);

    useEffect(() => {
        if (!loadAddThis && shouldLoad) {
            appendScript(setLoadAddThis);
        }
        if (loadAddThis && isBrowser && window.addthis) {
            window?.addthis?.layers?.refresh();
        }
    }, [loadAddThis, shouldLoad, isBrowser]);
};

const appendScript = (setLoadAddThis) => {
    if (window.addthis) return setLoadAddThis(true);
    const addThisScript = document.createElement("script");
    addThisScript.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${process.env.GATSBY_ADDTHIS_PUBLIC_ID}`;

    document.body.appendChild(addThisScript);
};
